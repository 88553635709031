<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="4">
        <el-select
          v-model="query.operationId"
          placeholder="请选择代理商"
          :maxLength="50"
          clearable
        >
          <el-option
            v-for="(item, i) in agentList"
            :value="item.id"
            :label="item.operationName"
            :key="i"
          ></el-option>
        </el-select>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
      </el-col>
    </el-row>
    <div>
     
    </div>
    
    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column
        label="代理商名称"
        prop="operationName" 
      ></el-table-column>
      <el-table-column label="代理商类型" prop="type">
        <template #default="scope">
          <view v-if="scope.row.type === this.const.AGENT_LEVEL_CODE.SELF">本公司</view>
          <eb-badge
            :list="this.const.AGENT_LEVEL"
            :target="scope.row.type"
          ></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="代理地区" prop="province">
        <template #default="scope">
          <span>{{
            scope.row.province + scope.row.city + scope.row.area
          }}</span>
        </template>
      </el-table-column>

      <el-table-column label="负责人" prop="truename"></el-table-column>
      <el-table-column label="联系方式" prop="phone"></el-table-column>
      <el-table-column label="邮箱" prop="eamil"></el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
          <span class="option option-primary" @click="showEditAgent(scope.row)"  v-auth="this.per.AGENT_UPDATA"
            >编辑</span
          >
          <span class="option option-danger" @click="doDelAgent(scope.row)"  v-auth="this.per.AGENT_DEL"
            >删除</span
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout=" total,prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>
  </div>
</template>

<script>
import { agentUserCharList} from "@/api/agent";
import { areas } from "@/common/area.js";
import { mapState } from "vuex";
export default {
  computed: mapState({
    agentList: (state) => {
      return state.agent.agentList || { list: [], map: {} };
    },
  }),
  data() {
    return {
      
      dataSource: [],
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      query: {},
      modalData: {},
      modalType: "add",
      MODAL_KEY: {
        EDIT_MODAL: "EDIT_MODAL",
      },
      total: 0,
    };
  },
  methods: { 
    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      agentUserCharList({ ...this.query, ...this.pages }).then(
        (res) => {
          this.dataSource = res.data.data.records;
          this.total = res.data.data.total;
        }
      );
    },

    initData() {
      this.$store.dispatch("fetchAgentListAll");
      this.getList();
    },
  },

  mounted() {
    this.initData();
  },
};
</script>
